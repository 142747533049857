"use client";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { WindowWithDataLayer } from "@/app/types/WindowDataLayer";
import handleDataLayerOnClick from "@/app/helpers/analytics/handleDataLayerOnClick";

declare const window: WindowWithDataLayer;

type InteractiveLinkProps = {
	url?: any;
	blockData?: any;
	title?: any;
	children?: any;
	cardPosition?: any;
	isLink?: any;
	className?: string;
	target?: string;
	params?: string;
	role?: string;
};

export default function InteractiveLink({
	url,
	blockData,
	title,
	children,
	cardPosition,
	isLink,
	className = "",
	target = "_self",
	params = "",
	role = "",
}: InteractiveLinkProps) {
	blockData.contentTitle = title;
	const path = usePathname();
	const trailingPath = `${url}/`?.replace(/\/+$/, "/");
	return (
		<Link
			href={
				`${trailingPath}${
					params && trailingPath.includes("en-vivo") ? "?" + params : ""
				}` || ""
			}
			role={role}
			title={title || "Noticia N+"}
			aria-label={title || "Noticia N+"}
			onClick={() =>
				handleDataLayerOnClick(blockData, cardPosition, path, isLink)
			}
			className={className}
			target={target}
			prefetch={false}
		>
			{children}
		</Link>
	);
}
